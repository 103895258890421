(function () {
	'use strict';

	angular
		.module('app')
		.factory('filemakerScriptConfig', [
			'$sce',
			'utilities',
			filemakerScriptConfig,
		]);

	function filemakerScriptConfig($sce, utilities) {
		return {
			config: config,
			fieldMap: fieldMap,
			unusedMap: unusedMap,
			allowHTMLMap: allowHTMLMap,
			hiddenFieldMap: hiddenFieldMap,
			readOnlyFieldMap: readOnlyFieldMap,
		};

		function config() {
			return {
				id: 1,
				name: 'FileMaker Client',
				propertyName: 'filemakerClient',
				getApiConnection: null,
				available: utilities.getDBKPlatform() === 'dbkfm',
				editable: true,
				isPrimary: true,
				async: false,
				colorType: 'rgba',
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: `${_CONFIG.DBK_BASEURL}images/FileMakerBigAlt.png`,
				eventFocus: null,
				refresh: null,
				useISOTimestamp: false,
				useDefaultFieldMap: false,
				customActionsEditable: false,

				settings: {
					//setting: the name of the setting, visible: wether or not the setting is user facing, defaultValue: the default value if any
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 1,
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: false,
						defaultValue: false,
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						defaultValue: 'New FileMaker Calendar',
						format: 'name',
						helptext:
							'Your own name for these events, like "Company Projects".',
					},
					fileName: {
						setting: 'fileName',
						visible: true,
						displayValue: 'FileMaker file name',
						defaultValue: 'YourFile.fmp12',
						helptext:
							'The name of your FileMaker file with the .fmp12 extension.',
					},
					layoutName: {
						setting: 'layoutName',
						visible: true,
						displayValue: 'Source layout name',
						defaultValue: 'Your Layout Name',
						helptext:
							'Each table of events you\'d like to see in DayBack requires its own layout (and its own source entry here). If you\'re using DayBack.fmp12 or have DayBack integrated into your FileMaker Pro solution then use one of your "Source No X" layouts here.<br><br> \
						If you\'re not using DayBack in your solution already, please make a new layout for this. Here are a few things to double check on this new layout <br> \
						<ul><li>The layout should be based on the events table you wish to publish (it should the table that contains the "Event id" field you specify below.)</li> \
						<li>The layout should contain NO script triggers and no fields other than the ones specific in "Field Mapping" below.</li> \
						<li>The layout should contain ALL the fields mentioned in "Field Mapping" below.</li> \
						<li>The privilege set used for the account name and password above (in your PHP relay file) must have read/write access to this layout.</li></ul>',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: true,
						displayValue: "FileMaker file's date format",
						defaultValue: 'MM/DD/YYYY',
						helptext:
							'This is the format DayBack will use when writing event changes back to your FileMaker file. Settings for how <em>dates are displayed</em> can be found in Admin Settings under "Date / Time Formats".<br><br> \
						For files created in the United States this format is probably MM/DD/YYYY. For files created in the EU it is likely DD/MM/YYYY. If you get this correct you\'ll be able to drag events from one date to another without issue. If this format is incorrect, dragging an existing event to a new date will throw the following error, telling you that you\'ve got the format wrong:<br><br><div class="exampleCode"> \
								"There was an error saving the event and your changes will be reverted: 500 - Date value does not meet validation entry options" \
							<br><br></div>',
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(244, 244, 244, 0.85)',
						format: 'colorPicker',
						helptext:
							'The status color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
									<div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
							 ...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: true,
						displayValue: 'Read-Only',
						defaultValue: false,
						format: 'yesno',
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
					defaultDrawer: {
						setting: 'defaultDrawer',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default Popover Drawer',
						defaultValue: '',
						format: 'select',
						options: [
							{id: '', label: ''},
							{id: 'dateStart', label: 'Start'},
							{id: 'dateEnd', label: 'End'},
							{id: 'calendar', label: 'Calendar'},
							{id: 'location', label: 'Location'},
							{id: 'resource', label: 'Resource'},
							{id: 'status', label: 'Status'},
							{id: 'contact', label: 'Contact'},
							{id: 'project', label: 'Project'},
							{id: 'customFields', label: 'Custom Fields'},
							{id: 'action', label: 'Button Actions'},
						],
						helptext:
							'Optional. Choose a drawer that should be shown automatically when clicking on an event.</br>  <a href="https://docs.dayback.com/article/167-drawers">Learn more about drawers here.</a>',
					},
				},
			};
		}

		function fieldMap() {
			return {
				eventSource: {
					setting: 'eventSource',
					visible: false,
					defaultValue: 'eventSource',
				},
				eventID: {
					setting: 'eventID',
					visible: false,
					defaultValue: 'eventID',
				},
				allDay: {
					setting: 'allDay',
					visible: false,
					defaultValue: 'allDay',
				},
				start: {
					setting: 'start',
					visible: false,
					defaultValue: 'start',
				},
				end: {
					setting: 'end',
					visible: false,
					defaultValue: 'end',
				},
				title: {
					setting: 'title',
					visible: false,
					defaultValue: 'title',
				},
				titleEdit: {
					setting: 'titleEdit',
					visible: false,
					defaultValue: 'titleEdit',
				},
				description: {
					setting: 'description',
					visible: false,
					defaultValue: 'description',
				},
				resource: {
					setting: 'resource',
					visible: false,
					defaultValue: 'resource',
				},
				status: {
					setting: 'status',
					visible: false,
					defaultValue: 'status',
				},
				contactID: {
					setting: 'contactID',
					visible: false,
					defaultValue: 'contactID',
				},
				contactName: {
					setting: 'contactName',
					visible: false,
					defaultValue: 'contactName',
				},
				projectID: {
					setting: 'projectID',
					visible: false,
					defaultValue: 'projectID',
				},
				projectName: {
					setting: 'projectName',
					visible: false,
					defaultValue: 'projectName',
				},
				location: {
					setting: 'location',
					visible: false,
					defaultValue: 'location',
				},
				tags: {
					setting: 'tags',
					visible: false,
					defaultValue: 'tags',
				},
			};
		}

		function unusedMap() {
			return {
				parentListID: true,
				parentListName: true,
				done: true,
			};
		}

		function allowHTMLMap() {
			return {};
		}
		function hiddenFieldMap() {
			return {};
		}
		function readOnlyFieldMap() {
			return {};
		}
	}
})();
